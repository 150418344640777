











import { defineComponent, reactive, toRefs } from "@vue/composition-api"
import QrCodeImporter from "./QrCodeImporter.vue"
import QrCodeImportListFromPlaceList from "./QrCodeImportListFromPlaceList.vue"

export default defineComponent({
    name: "QrCodeBatch",
    components: {
        QrCodeImporter,
        QrCodeImportListFromPlaceList,
    },
    setup() {
        const state = reactive({
            tabId: 1,
        })

        function changeTab(tabId: number) {
            state.tabId = tabId
        }

        return {
            ...toRefs(state),
            changeTab,
        }
    },
})
