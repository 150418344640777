import { MissionType } from "@/services/MissionsService"
import { FieldType, FieldValue } from "@/services/MissionTemplatesService"
import {
    Coordinate,
    Label,
    Mileage,
    SpaceObjectDTO,
} from "@/services/SpaceObjectsService"
import { WorkBook } from "xlsx"
import { ParsedMissionSignatureFieldTemplate } from "./MissionSignFieldTemplateSheetParser"
import { ParsedPlaceCustomProperty } from "./PlaceCustomPropertySheetParser"
import { readFileToWorkbook } from "./XLSXParser"

export interface ParsingError {
    sheet: string
    row: number
    col: string
    err: string
}

export interface ColumnError {
    col: string
    err: string
}

export type ImportState = "PARSED" | "IMPORTING" | "DONE" | "ERROR" | "REMOVING"

export interface ParsedItem {
    sheet: string
    sourceRow: number
    errors: ColumnError[]
}

export interface ParsedMissionTemplate extends ParsedItem {
    id?: string
    type: MissionType | null
    subject?: string
    description: string
    duration: number | null
    managers: string[]
    managerIds?: string[]
    cooperators: string[]
    cooperatorIds?: string[]
    assignees: string[]
    assigneeIds?: string[]
    missionFieldTemplates: ParsedMissionFieldTemplate[]
    signatureFieldTemplates: ParsedMissionSignatureFieldTemplate[]
}

export interface ParsedMissionFieldTemplate extends ParsedItem {
    missionTemplateId?: string
    sortIndex: number | null
    fieldTemplateId?: string | null
    spaceObjectId?: string | null
    spaceObject?: string
    subject?: string
    description?: string
    type: FieldType | null
    selectOptions: string[]
    defaultValue: FieldValue
    errors: ColumnError[]
}

export function tryParseInt(s: string): number | null {
    const number = parseInt(s)
    const isNumber = !isNaN(number)
    return isNumber ? number : null
}

export function tryParseFloat(s: string): number | null {
    const number = parseFloat(s)
    const isNumber = !isNaN(number)
    return isNumber ? number : null
}

export function parseCommaSplitString(s: string) {
    return s
        .split(",")
        .map((a) => a.trim())
        .filter((a) => a !== "")
}

export interface ParsedSpaceObject extends ParsedItem {
    id?: string
    parentId?: string
    location?: SpaceObjectDTO
    number?: string
    name?: string
    category?: string
    mileageText?: string
    mileage?: Mileage
    coordinate?: Coordinate
    bimId?: string
    labels?: Label[]
    customProperties?: ParsedPlaceCustomProperty[]
}

export interface ParsedRemoveSpaceObject extends ParsedItem {
    id?: string
    location?: SpaceObjectDTO
    number?: string
    name?: string
    category: string | null
    mileage: Mileage | null
}

/** 檢查是否為合法ID */
export function checkId(id: string) {
    return /^[a-zA-Z0-9_-]{1,100}$/.test(id)
}

export interface ParsedQrCode extends ParsedItem {
    id?: string
    subject?: string
    navigationPlaceId?: string
    navigationPlace?: SpaceObjectDTO
    unlocks: ParsedUnlock[]
}

export interface ParsedUnlock extends ParsedItem {
    id?: string
    placeId?: string
    place?: SpaceObjectDTO
}

export interface ParsedQrCodePlace extends ParsedItem {
    id?: string
    navigationPlaceId?: string
    navigationPlace?: SpaceObjectDTO
}

export class KnownParsingError extends Error {
    constructor(message: string) {
        super(message)
        this.name = "KnownParsingError"
    }
}

export async function openBookFromFile(file: File) {
    try {
        return await readFileToWorkbook(file)
    } catch (error) {
        throw new KnownParsingError("請確認輸入的檔案是XLSX")
    }
}

export function ensureSheetExist(book: WorkBook, sheetName: string) {
    if (!book.Sheets[sheetName])
        throw new KnownParsingError(`請確認有"${sheetName}"試算表`)
}

export function collectErrorsFromParsedItems(parsedItems: ParsedItem[]) {
    return parsedItems.flatMap((f) =>
        f.errors.map(
            (e) =>
                <ParsingError>{
                    sheet: f.sheet,
                    row: f.sourceRow,
                    col: e.col,
                    err: e.err,
                }
        )
    )
}
