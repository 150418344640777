


























































































































import {
    computed,
    defineComponent,
    reactive,
    toRefs,
    watch,
} from "@vue/composition-api"
import ParsedErrorSpan from "@/components/ParsedErrorSpan.vue"
import { WorkBookDto } from "@/utilities/XLSXParser"
import {
    closeDialog,
    DialogButtonType,
    errorDialog,
    infoDialog,
    setMessage,
    successDialog,
} from "@/services/DialogService"

import {
    checkId,
    ColumnError,
    ensureSheetExist,
    KnownParsingError,
    openBookFromFile,
    ParsedQrCode,
    ParsedUnlock,
    ParsingError,
} from "@/utilities/Parser"
import { setQrCode, SpaceObjectCache } from "@/services/SpaceObjectsService"
import lodash from "lodash"
import { WorkBook } from "xlsx"
const SHEET_NAME = "QR Code 清單"
const UNLOCK_SHEET_NAME = "解鎖清單"

interface QrCodeImportRow {
    ID?: string
    主旨?: string
    導覽地點ID?: string
}

interface UnlockImportRow {
    ID?: string
    解鎖地點ID?: string
}

export default defineComponent({
    name: "QrCodeImporter",
    components: {
        ImporterParseErrorTable: () =>
            import("../../components/ImporterParseErrorTable.vue"),
        ParsedErrorSpan,
    },
    setup() {
        const state = reactive({
            file: null as File | null,
            items: [] as ParsedQrCode[],
            errors: [] as ParsingError[],
            page: 1,
        })
        const totalPages = computed(() => Math.ceil(state.items.length / 20))
        const pageItems = computed(() =>
            lodash(state.items)
                .drop((state.page - 1) * 20)
                .take(20)
                .value()
        )

        async function parseFile(file: File) {
            try {
                infoDialog("解析中...", "", DialogButtonType.None)
                const book = await openBookFromFile(file)
                checkSheetsExist(book.book)
                const parsedUnlocks = await parseUnlocks(book)
                const unlockErrors = parsedUnlocks.flatMap((u) =>
                    u.errors.map(
                        (e) =>
                            <ParsingError>{
                                sheet: u.sheet,
                                row: u.sourceRow,
                                col: e.col,
                                err: e.err,
                            }
                    )
                )
                state.errors.push(...unlockErrors)

                const parsedQrCode = await parseQrCodeFromBook(
                    book,
                    parsedUnlocks
                )
                const qrCodeErrors = parsedQrCode.flatMap((q) =>
                    q.errors.map(
                        (e) =>
                            <ParsingError>{
                                sheet: q.sheet,
                                row: q.sourceRow,
                                col: e.col,
                                err: e.err,
                            }
                    )
                )
                state.errors.push(...qrCodeErrors)
                state.items = parsedQrCode

                closeDialog()
                if (state.errors.length) {
                    errorDialog("解析發生錯誤", "請檢查匯入格式或資料是否正確")
                    return
                }
                successDialog("解析完成")
            } catch (error) {
                closeDialog()
                const msg =
                    error instanceof KnownParsingError
                        ? error.message
                        : "解析過程發生不明錯誤"

                errorDialog(msg)
            }
        }

        function clear() {
            state.items = []
            state.errors = []
            state.page = 1
        }

        function checkSheetsExist(book: WorkBook) {
            ensureSheetExist(book, UNLOCK_SHEET_NAME)
            ensureSheetExist(book, SHEET_NAME)
        }

        async function parseUnlocks(book: WorkBookDto) {
            const parsedUnlockKey = new Set<string>()
            const unlocks = book
                .getSheetByName<UnlockImportRow>(UNLOCK_SHEET_NAME, {
                    raw: false,
                })
                .map((row, rowIndex) => {
                    const errors = [] as ColumnError[]
                    function addError(col: string, err: string) {
                        errors.push({
                            col,
                            err,
                        })
                    }
                    if (!row.ID) addError("ID", "ID 必填")

                    if (row.ID && !checkId(row.ID))
                        addError(
                            "ID",
                            "ID 必須為英文數字底線減號的組合,長度100以內"
                        )
                    if (!row.解鎖地點ID)
                        addError("解鎖地點ID", "解鎖地點ID 必填")
                    if (row.ID && row.解鎖地點ID) {
                        const key = buildUnlockKey(row.ID, row.解鎖地點ID)
                        if (parsedUnlockKey.has(key)) {
                            addError(
                                "解鎖地點ID",
                                "本次匯入資料 QrcodeID-解鎖地點ID 重複"
                            )
                        } else parsedUnlockKey.add(key)
                    }

                    const result: ParsedUnlock = {
                        sheet: UNLOCK_SHEET_NAME,
                        sourceRow: rowIndex + 2,
                        id: row.ID || undefined,
                        placeId: row.解鎖地點ID,
                        errors,
                    }
                    return result
                })

            await SpaceObjectCache.updateByIds(
                unlocks
                    .map((item) => item.placeId)
                    .filter((id) => !!id) as string[]
            )

            unlocks.forEach((item) => {
                if (!item.placeId) return
                const match = SpaceObjectCache.get(item.placeId)
                if (!match) {
                    item.errors.push({
                        col: "解鎖地點ID",
                        err: "無法對照到解鎖地點",
                    })
                    return
                }
                item.place = match
            })

            return unlocks
        }

        async function parseQrCodeFromBook(
            book: WorkBookDto,
            parsedUnlock: ParsedUnlock[]
        ) {
            const parsedQrcodeIds = new Set<string>()

            const parsedQrcode = book
                .getSheetByName<QrCodeImportRow>(SHEET_NAME, {
                    raw: false,
                })
                .map((row, rowIndex) => {
                    const errors = [] as ColumnError[]
                    function addError(col: string, err: string) {
                        errors.push({
                            col,
                            err,
                        })
                    }
                    if (row.ID && !checkId(row.ID))
                        addError(
                            "ID",
                            "ID 必須為英文數字底線減號的組合,長度100以內"
                        )
                    if (row.ID && parsedQrcodeIds.has(row.ID))
                        addError("ID", "本次匯入資料 ID 重複")
                    if (row.ID) parsedQrcodeIds.add(row.ID)
                    if (!row.導覽地點ID)
                        addError("導覽地點ID", "導覽地點ID 必填")

                    const result: ParsedQrCode = {
                        sheet: SHEET_NAME,
                        sourceRow: rowIndex + 2,
                        id: row.ID || undefined,
                        subject: row.主旨,
                        navigationPlaceId: row.導覽地點ID,
                        unlocks: [],
                        errors,
                    }
                    return result
                })

            await SpaceObjectCache.updateByIds(
                parsedQrcode
                    .map((item) => item.navigationPlaceId)
                    .filter((id) => !!id) as string[]
            )

            parsedQrcode.forEach((item) => {
                item.unlocks = parsedUnlock.filter((u) => u.id === item.id)
                if (!item.navigationPlaceId) return
                const match = SpaceObjectCache.get(item.navigationPlaceId)
                if (!match) {
                    item.errors.push({
                        col: "導覽地點ID",
                        err: "無法對照到導覽地點",
                    })
                    return
                }
                item.navigationPlace = match
            })

            return parsedQrcode
        }

        function buildUnlockKey(qrcodeId: string, placeId: string) {
            return `${qrcodeId}::${placeId}`
        }

        watch(
            () => state.file,
            async (file) => {
                clear()
                if (file) {
                    parseFile(file)
                }
            }
        )

        async function startImport() {
            if (state.errors.length) {
                errorDialog("尚未處理解析錯誤，無法匯入")
                return
            }
            infoDialog("匯入中，請稍後", "", DialogButtonType.None)
            for (let i = 0; i < state.items.length; i++) {
                const item = state.items[i]
                try {
                    await setQrCode({
                        id: item.id,
                        subject:
                            item.subject ?? item.navigationPlace?.name ?? "",
                        navigationPlaceId: item.navigationPlaceId!,
                        unlockSettings: item.unlocks.map((u) => ({
                            placeId: u.placeId!,
                            unlockLifetime: 480,
                        })),
                    })
                    setMessage(`${i} / ${state.items.length}`)
                } catch (error) {
                    closeDialog()
                    errorDialog(
                        `第${item.sourceRow}列 匯入發生不預期錯誤，請聯絡系統維護人員`,
                        `${error}`
                    )
                    return
                }
            }
            closeDialog()
            successDialog("匯入完成")
        }

        return {
            ...toRefs(state),
            startImport,
            totalPages,
            pageItems,
        }
    },
})
