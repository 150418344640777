



























































import {
    computed,
    defineComponent,
    reactive,
    toRefs,
    watch,
} from "@vue/composition-api"
import ParsedErrorSpan from "@/components/ParsedErrorSpan.vue"
import {
    closeDialog,
    DialogButtonType,
    errorDialog,
    infoDialog,
    successDialog,
} from "@/services/DialogService"
import {
    collectErrorsFromParsedItems,
    ColumnError,
    ensureSheetExist,
    KnownParsingError,
    openBookFromFile,
    ParsedQrCodePlace,
    ParsingError,
} from "@/utilities/Parser"
import {
    genQrCodeImportListFromPlaceList,
    SpaceObjectCache,
} from "@/services/SpaceObjectsService"
import lodash from "lodash"
import { WorkBookDto } from "@/utilities/XLSXParser"
const SHEET_NAME = "導覽地點清單"

interface QrCodeImportRow {
    導覽地點ID?: string
}

export default defineComponent({
    name: "QrCodeImportListFromPlaceList",
    components: {
        ImporterParseErrorTable: () =>
            import("../../components/ImporterParseErrorTable.vue"),
        ParsedErrorSpan,
    },
    setup() {
        const state = reactive({
            file: null as File | null,
            items: [] as ParsedQrCodePlace[],
            errors: [] as ParsingError[],
            page: 1,
        })
        const totalPages = computed(() => Math.ceil(state.items.length / 20))
        const pageItems = computed(() =>
            lodash(state.items)
                .drop((state.page - 1) * 20)
                .take(20)
                .value()
        )

        async function parseFile(file: File) {
            try {
                infoDialog("解析中...", "", DialogButtonType.None)
                const book = await openBookFromFile(file)
                ensureSheetExist(book.book, SHEET_NAME)
                const qrcodePlaces = await parseParsedQrCodePlaceFromBook(book)
                const errors = collectErrorsFromParsedItems(qrcodePlaces)
                state.items = qrcodePlaces
                state.errors = errors

                closeDialog()
                if (state.errors.length) {
                    errorDialog("解析發生錯誤", "請檢查匯入格式或資料是否正確")
                    return
                }
                successDialog("解析完成")
            } catch (error) {
                closeDialog()
                const msg =
                    error instanceof KnownParsingError
                        ? error.message
                        : "解析過程發生不明錯誤"

                errorDialog(msg)
            }
        }

        async function parseParsedQrCodePlaceFromBook(book: WorkBookDto) {
            const parsedQrcode = book
                .getSheetByName<QrCodeImportRow>(SHEET_NAME, {
                    raw: false,
                })
                .map((row, rowIndex) => {
                    const errors = [] as ColumnError[]
                    function addError(col: string, err: string) {
                        errors.push({
                            col,
                            err,
                        })
                    }
                    if (!row.導覽地點ID)
                        addError("導覽地點ID", "導覽地點ID 必填")

                    const result: ParsedQrCodePlace = {
                        sheet: SHEET_NAME,
                        sourceRow: rowIndex + 2,
                        navigationPlaceId: row.導覽地點ID,
                        errors,
                    }
                    return result
                })

            await SpaceObjectCache.updateByIds(
                parsedQrcode
                    .map((item) => item.navigationPlaceId)
                    .filter((id) => !!id) as string[]
            )

            parsedQrcode.forEach((item) => {
                if (!item.navigationPlaceId) return
                const match = SpaceObjectCache.get(item.navigationPlaceId)
                if (!match) {
                    item.errors.push({
                        col: "導覽地點ID",
                        err: "無法對照到導覽地點",
                    })
                    return
                }
                item.navigationPlace = match
            })
            return parsedQrcode
        }

        watch(
            () => state.file,
            async (file) => {
                if (!file) {
                    state.items = []
                    return
                }
                parseFile(file)
            }
        )

        async function startImport() {
            if (state.errors.length) {
                errorDialog("尚未處理解析錯誤，無法匯入")
                return
            }
            infoDialog("匯入中，請稍後", "", DialogButtonType.None)
            await genQrCodeImportListFromPlaceList(state.file!)
            closeDialog()
        }

        return {
            ...toRefs(state),
            startImport,
            totalPages,
            pageItems,
        }
    },
})
